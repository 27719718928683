import React from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import './css/Footer.css';
const Footer = () => {
  return (
    <footer className="footer mt-4">
      <Container>
        <Row>
          <Col className="d-flex" md={9}>
            <Nav className="flex-row">
              <Nav.Link as={NavLink} to="/quem-somos" activeclassname="active">Sobre nós</Nav.Link>
              <Nav.Link as={NavLink} to="/anuncie" activeclassname="active">Quero que me liguem grátis</Nav.Link>
              <Nav.Link as={NavLink} to="/faqs" activeclassname="active">Solicitação de Coleta</Nav.Link>
              <Nav.Link as={NavLink} to="/termos-de-uso" activeclassname="active">Quem Somos</Nav.Link>
              <Nav.Link as={NavLink} to="/fale-conosco" activeclassname="active">Fale Conosco</Nav.Link>
            </Nav>
          </Col>
          <Col className="d-flex" md={3}>
            <Button variant="link" href="https://www.facebook.com/centraldasbaterias/?ref=embed_page" target='_blank'><FontAwesomeIcon icon={faFacebook} size="lg" /></Button>
            <Button variant="link" href="https://www.instagram.com/centraldasbateriasmaceio/" target='_blank'><FontAwesomeIcon icon={faInstagram} size="lg" /></Button>  
            <Button variant="link" href="https://api.whatsapp.com/send?phone=5582988490802" target='_blank'><FontAwesomeIcon icon={faWhatsapp} size="lg" /></Button>  
            <Button variant="link" href="https://www.youtube.com/@centraldasbateriasmaceio1715" target='_blank'><FontAwesomeIcon icon={faYoutube} size="lg" /></Button>  
          </Col>
          <Col md={12}>
            <p>&copy; Copyright 2005-2024 | Todos os Direitos Reservados - {process.env.REACT_APP_NAME}.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
