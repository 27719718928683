import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './css/Header.css';

const Header = () => {
  return (
    <header className="header d-flex justify-content-between align-items-center py-3 flex-wrap">
      <div className="logo-container d-flex align-items-center">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Passeei Logo" className="logo" />
        </Link>
      </div>
      <Nav className="header-nav d-flex justify-content-end align-items-center flex-wrap">
        <Nav.Link as={NavLink} to="/" className="nav-item" activeclassname="active">
          Principal
        </Nav.Link>
        <Nav.Link as={NavLink} to="/quem-somos" className="nav-item" activeclassname="active">
          Quem Somos
        </Nav.Link>
        <Nav.Link as={NavLink} to="/passeios" className="nav-item" activeclassname="active">
          Coleta de Baterias
        </Nav.Link> 
        <Nav.Link as={NavLink} to={'https://api.whatsapp.com/send?phone=5582988490802'} className="nav-item">
          <Button variant="success" className="whatsapp-button" title='Pedir Orçamento'>
            <FontAwesomeIcon icon={faWhatsapp} size="sm" /> (82) 98849-0802
          </Button>
        </Nav.Link>
      </Nav>
    </header>
  );
};

export default Header;
