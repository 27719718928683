import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import sobre from './../assets/images/sobre.webp';
import './css/AboutUs.css';

const AboutUs = () => {
  return (
    <Container fluid className="about-section text-left py-5">
      <Container>
        <h2 className="mb-5 text-center text-white">
        Conheça um pouco mais sobre nós
        </h2>
        <Row>
          <Col md={6} className="mb-4">
            <div className="about-img">
              <Image src={sobre} alt="Fachada" className="img-fluid rounded" />
            </div>
          </Col> 
          <Col md={6} className="mb-2 text-white"> 
            <p>Somos uma empresa especializada na venda, instalação e manutenção preventiva de baterias para carros, caminhões, motos, maquinas agrícolas, embarcações náuticas, jet skys, grupos geradores, carros elétricos e sistemas estacionários (especificas para Nobreaks de informática e emergência).</p>
            <p>Dispomos de técnicos treinados nas principais fabricas de baterias do Brasil.</p>
            <p>Analisamos as baterias com equipamentos de alta tecnologia, proporcionando uma resposta elétrica mais precisa.</p>
            <p>Comercializamos as marcas de baterias mais conceituadas do mercado como Moura, Heliar, Pioneiro, Zetta, Tudor, Naja e outras.</p>
            <p>Facilitamos o pagamento nos cartões de crédito Visa, Master, Hiper e Dinners.</p>
            <p>Executamos serviços em motores de partida e alternadores de toda a linha automotiva leve e pesada 12 volts.</p>
            <p>Temos peças autoelétricas para motores de partida e alternadores de toda linha automotiva leve e pesada 12 volts. A nossa missão é oferecer soluções em diversas aplicações de baterias de maneira técnica e, sobretudo com ética. Respeitando e satisfazendo clientes, colaboradores, fornecedores e parceiros comerciais.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutUs;
