import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './css/Contact.css';

const Contact = () => {
  const mapStyles = {
    height: "450px",
    width: "100%"
  };

  const defaultCenter = {
    lat: -9.65686,
    lng: -35.715804
  };

  return (
    <Container fluid className="contact-section text-left py-1">
      <Container>
        <h2 className="mb-5 text-center">
          Fale Conosco
        </h2>
        <Row>
          <Col md={6} className="mb-4">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={19}
                center={defaultCenter}
              >
                <Marker position={defaultCenter} title={process.env.REACT_APP_NAME} />
              </GoogleMap>
            </LoadScript>
          </Col>
          <Col md={6} className="mb-2">
            <Form className="">
              <Form.Group controlId="formBasicName">
                <Form.Label>Nome</Form.Label>
                <Form.Control type="text" placeholder="Digite seu nome" required />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Digite seu email" required />
              </Form.Group>
              <Form.Group controlId="formBasicPhone">
                <Form.Label>Telefone</Form.Label>
                <Form.Control type="tel" placeholder="Digite seu telefone" required />
              </Form.Group>
              <Form.Group controlId="formBasicMessage">
                <Form.Label>Mensagem</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Digite sua mensagem" required />
              </Form.Group>
              <Button variant="primary" type="submit" size="lg" className="mt-3">
                Enviar Mensagem
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Contact;
